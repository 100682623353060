import Head from 'next/head';
import {FAVICON_IMG, ROOT_DOMAIN, SITE_NAME} from "../../config/constant/projectConstant";
import {useRouter} from 'next/router'
import { Fragment } from 'react';
import { titleFixer } from '../site-pages/siteBasic';

const HeadContainer = (props) => {
    const router = useRouter();
    const {title = '', meta = [],icon="",canonical=null} = props;

    return (
        <Head>
            <meta name="viewport" content="width=device-width, initial-scale=1"/>
            <meta httpEquiv="Content-Type" content="text/html; charset=utf-8"/>
            <meta name="facebook-domain-verification" content="a6x3go61ttqolnqi1ejt0y9euuw0e4" />
            <meta name="theme-color" content="#000000"/>
            <link rel="icon" href={icon ? icon : '/favicon.png'}/>
            <link rel="stylesheet" href="/assets/css/common.css"/>
            <link rel="canonical" href={canonical ? canonical : `${ROOT_DOMAIN}${router.asPath.replace('/site-pages','')}`}/>
            <title>{titleFixer(title)} | {SITE_NAME}</title>
            {meta && meta.map((a) => {
                if(a.property === 'og:title' || a.name === 'og:title'){
                    a.content = titleFixer(a?.content ?? '') +' | '+SITE_NAME
                }
                if(a.property === 'og:image'){
                    a.content = a.content.indexOf('female-avatar') > -1 ? FAVICON_IMG : a.content
                }
                let twitterMeta = '' 
                if((a.property && a.property.indexOf('og:') > -1 ) || (a.name && a.name.indexOf('og:') > -1)){
                    twitterMeta = {...a}
                    twitterMeta.name = a.name ? a.name.replace('og:', 'twitter:') : a.property.replace('og:', 'twitter:')
                    twitterMeta.name = twitterMeta.name === 'twitter:site_name' ? 'twitter:site' : twitterMeta.name
                    delete twitterMeta.property
                }
                return a.content.length > 0 && <Fragment  key={a?.name ?? a?.property}>
                     <meta {...a}/>
                     {twitterMeta && <meta {...twitterMeta}/>}
                </Fragment>
            })}
            <meta name="twitter:card" content="summary_large_image"/>
            {props.children}
        </Head>
    );
};
export default HeadContainer;
